<template>
  <div class="app-login v">
    <div class="aidream_title c h">
      <img src="../assets/images/small-aidream.jpg" alt />
      <div class="fc-l">|</div>
      <div class="fc-g" style="margin:10px 0;padding:20px 0 20px 15px">爱梦智能家居</div>
    </div>
    <div class="login">
      <el-form ref="loginForm" :model="loginForm" :rules="loginRules" label-position="left" label-width="0px" class="login-form">
        <div class="c h log-image">
          <div class="log">
            <img src="../assets/images/serta_logo.png" class="default-logo" />
          </div>
          <div class="log">
            <img src="../assets/images/kingKoil.png" class="default-logo" />
          </div>
        </div>

        <el-form-item prop="username">
          <el-input v-model="loginForm.username" type="text" auto-complete="off" placeholder="账号">
            <i slot="prefix" class="si si-user-circle" />
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input v-model="loginForm.password" type="password" auto-complete="off" placeholder="密码" @keyup.enter.native="handleLogin">
            <i slot="prefix" class="si si-password" />
          </el-input>
        </el-form-item>
        <el-form-item prop="code">
          <el-input v-model="loginForm.code" auto-complete="off" placeholder="验证码" style="width: 63%" @keyup.enter.native="handleLogin">
            <i slot="prefix" class="si si-shield" />
          </el-input>
          <div class="login-code">
            <img :src="codeUrl" @click="getCode" />
          </div>
        </el-form-item>
        <el-checkbox v-model="loginForm.rememberMe" style="margin:0 0 25px 0;">记住我</el-checkbox>
        <el-form-item style="width:100%;">
          <el-button :loading="loading" size="medium" type="primary" style="width:100%;" @click.native.prevent="handleLogin">
            <span v-if="!loading">登 录</span>
            <span v-else>登 录 中...</span>
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <!--  底部  -->
    <div class="login-footer">
      <span v-html="$store.state.settings.footerTxt" />
      <span>&nbsp;⋅&nbsp;</span>
      <a href="https://beian.miit.gov.cn" target="_blank">{{ $store.state.settings.caseNumber }}</a>
      <span>&nbsp;⋅&nbsp;</span>
      <a href="https://www.freepik.com/free-photos-vectors/business">Business vector created by fullvector - www.freepik.com</a>
    </div>
  </div>
</template>

<script>
import { encrypt } from "@/utils/rsaEncrypt";
import Config from "@/config";
import { getCodeImg } from "@/api/login";
import Cookies from "js-cookie";
import Background from "@/assets/images/home_backgroud2.jpg";
export default {
  data() {
    return {
      Background: Background,
      codeUrl: "",
      cookiePass: "",
      loginForm: {
        username: "",
        password: "",
        rememberMe: false,
        code: "",
        uuid: "",
      },
      loginRules: {
        username: [{ required: true, message: "用户名不能为空" }],
        password: [{ required: true, message: "密码不能为空" }],
        code: [{ required: true, message: "验证码不能为空" }],
      },
      loading: false,
      redirect: undefined,
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },
  created() {
    this.getCode();
    this.getCookie();
  },
  methods: {
    getCode() {
      getCodeImg().then((res) => {
        this.codeUrl = "data:image/gif;base64," + res.img;
        this.loginForm.uuid = res.uuid;
      });
    },
    getCookie() {
      const username = Cookies.get("username");
      let password = Cookies.get("password");
      const rememberMe = Cookies.get("rememberMe");
      // 保存cookie里面的加密后的密码
      this.cookiePass = password === undefined ? "" : password;
      password = password === undefined ? this.loginForm.password : password;
      this.loginForm = {
        username: username === undefined ? this.loginForm.username : username,
        password: password,
        rememberMe: rememberMe === undefined ? false : Boolean(rememberMe),
        code: "",
      };
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        const user = {
          username: this.loginForm.username,
          password: this.loginForm.password,
          rememberMe: this.loginForm.rememberMe,
          code: this.loginForm.code,
          uuid: this.loginForm.uuid,
        };
        if (user.password !== this.cookiePass) {
          user.password = encrypt(user.password);
        }
        if (valid) {
          this.loading = true;
          if (user.rememberMe) {
            Cookies.set("username", user.username, {
              expires: Config.passCookieExpires,
            });
            Cookies.set("password", user.password, {
              expires: Config.passCookieExpires,
            });
            Cookies.set("rememberMe", user.rememberMe, {
              expires: Config.passCookieExpires,
            });
          } else {
            Cookies.remove("username");
            Cookies.remove("password");
            Cookies.remove("rememberMe");
          }
          this.$store
            .dispatch("Login", user)
            .then(() => {
              this.loading = false;
              this.$router.push({ path: "/" });
            })
            .catch(() => {
              this.loading = false;
              this.getCode();
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss">
.app-login {
  height: 100%;
  background-color: #f2f6fc;
  .aidream_title {
    font-size: 25px !important;
    img {
      margin: 0px 15px 0px 50px;
    }
  }
}
.login {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  width: 100%;
  background-size: cover;
  background: url("../assets/images/home_backgroud2.jpg") no-repeat;
  .log-image {
    position: relative;
    top: -30px;
    left: -30px;
  }
  .logo {
    padding: 10px;
    .default-logo {
      width: 150px;
    }
  }
}
.title {
  margin: 0 auto 35px auto;
  text-align: center;
  color: #707070;
}

.login-form {
  border-radius: 6px;
  background: #ffffff;
  width: 385px;
  margin-right: 200px;
  padding: 25px 25px 5px 25px;
  .el-input {
    height: 38px;
    input {
      height: 38px;
    }
  }
  .input-icon {
    height: 39px;
    width: 14px;
    margin-left: 2px;
  }
}
.login-tip {
  font-size: 13px;
  text-align: center;
  color: #bfbfbf;
}
.login-code {
  width: 33%;
  display: inline-block;
  height: 38px;

  img {
    cursor: pointer;
    vertical-align: middle;
  }
}
.login-footer {
  position: fixed;
  left: 0;
  bottom: 20px;
  right: 0;
  text-align: center;
  font-size: 12px;
  color: #606266;
  opacity: 0.95;
}
</style>